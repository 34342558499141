body {
  margin: 0;
  font-family: "Bebas Neue", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  //transition: background-color 0.3s ease;
  overflow-x: hidden;
}

html {
  overflow-x: hidden;
}

* {
  //scroll-behavior: smooth;
}

.fader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 150px;
  left: 0px;
  background-color: #000000;
  transition: all 0.4s ease;
  z-index: 9999999999999;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.centered {
  display: flex;
  align-items: center;
}

.ant-select-dropdown {
  z-index: 999999999999 !important;
}

.ant-select-selection-item-content {
  color: #fff;
  font-family: 'RobotoCondensed' !important;
}

.loading {
  text-align: center;
  padding: 240px 0 0;
  position: absolute;
  z-index: 999999999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.swiper {
  padding-bottom: 90px;
}

@media screen and (max-width: 768px) {
  .swiper {
    padding-bottom: 40px;
  }
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 30px !important;
}

.swiper-pagination-bullet {
  background: #fff !important;
  transition: all 0.1s ease;
}

.swiper-pagination-bullet-active {
  background: #fff !important;
  border: 2px solid #fff !important;
  padding: 5px !important;
}

.col-35 {
  width: 25%;
  min-width: 25%;
  height: 100%;
}
.col-65 {
  width: 75%;
  min-width: 75%;
  height: 100%;
}
.col-12 {
  width: 100%;
  min-width: 100%;
  height: 100%;
}

.Toastify {
  position: relative;
  z-index: 9999999999999999;
  width: 100%;
}


.awssld__container {
  padding-bottom: 57% !important;
}

.awssld__controls button {
  width: 4% !important;
}

.awssld__controls__arrow-left:before,
.awssld__controls__arrow-left:after,
.awssld__controls__arrow-right:before,
.awssld__controls__arrow-right:after {
  background: #fff !important;
}

.awssld__bullets {
  bottom: 30px !important;
  z-index: 999999999 !important;
}

.awssld__bullets .awssld__bullets--active {
  background: #fff !important;
}

.awssld__bullets button {
  background: #ffffff4a !important;
}

.padded_content {
  padding-top: 120px;
  padding-bottom: 42px;
}

.breadcrumbs {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 62px;
  &.light {
    margin-bottom: 32px !important;
    .breadcrumbs_item {
      color: rgba(255, 255, 255, 0.5) !important;
      &:last-child {
        color: rgba(255, 255, 255, 0.8) !important;
      }
    }
  }
  .breadcrumbs_item {
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-left: 4px;
    &:first-child {
      margin-left: 4px;
    }
    &:last-child {
      color: rgba(0, 0, 0, 0.8);
    }
  }
}

.page_title {
  font-weight: 700;
  font-size: 48px;
  line-height: 59px;
  color: #242424;
  &.light {
    color: #fff;
  }
}

.page_subtitle {
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: #000000;
  &.light {
    color: #fff;
  }
}

.page_desc {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  &.light {
    color: #fff;
  }
}

@media screen and (max-width: 960px) {
  .awssld__container {
    padding-bottom: 160% !important;
  }
  .col-35, .col-65 {
    width: 100% !important;
    min-width: 100% !important;
  }
}

.awssld__controls__arrow-left,
.awssld__controls__arrow-right {
  height: 20px !important;
}

@media screen and (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1140px !important;
  }
}

.ant-collapse {
  border: none !important;
  border-radius: 0px !important;
  .ant-collapse-header-text {
    color: #fff !important;
    font-size: 22px !important;
    font-weight: 500;
  }
  .ant-collapse-expand-icon {
    color: #fff !important;
    height: 40px !important;
    span {
      font-size: 18px !important;
      svg {}
    }
  }
  .ant-collapse-content {
    background-color: transparent !important;
    border-top: none !important;
    color: #fff !important;
    .ant-collapse-content-box {
      padding-left: 0px !important;
      padding-right: 0px !important;
      padding-top: 0px !important;
      opacity: 0.8;
      p {
        color: #fff !important;
      }
    }
  }
  .ant-collapse-header {
    flex-direction: row-reverse !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .ant-collapse-item {
    border-radius: 0px !important;
    &:last-child {
      border-bottom: none !important;
    }
  }
}

.ant-message {
  z-index: 999999999999;
}

.ant-select {
  &.darked {
    color: #fff !important;
    .ant-select-selector {
      background-color: #000000 !important;
      border: 2px solid rgb(26 26 26) !important;
    }
    .ant-select-selection-item {
      background: rgb(255 255 255 / 17%) !important;
      border: 1px solid rgba(5, 5, 5, 0.06) !important;
    }
    .ant-select-selection-item-remove {
      color: #fff !important;
    }
    .ant-select-selection-placeholder {
      color: rgba(255, 255, 255, 0.25) !important;
    }
    .ant-select-arrow {
      color: rgba(255, 255, 255, 0.25) !important;
    }
    .ant-select-selector {
      padding: 2.3px 4px !important;
    }
    .ant-select-clear {
      color: #969696 !important;
      background: transparent !important;
    }
  }
}

.without-animations {
  * {
    //transition: none !important;
    animation: unset !important;
  }
}

.ant-upload-drag-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  img {
    width: 80px;
    margin-right: 8px;
    &:last-child {
      margin-right: 0px !important;
    }
  }
}

@media screen and (max-width: 960px) {
  .ant-collapse .ant-collapse-header-text {
    font-size: 16px !important;
    letter-spacing: 0.6px;
  }
  .ant-collapse .ant-collapse-expand-icon {
    height: 30px !important;
  }
}

@media screen and (min-width: 768px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: unset !important
  }
}

@media screen and (max-width: 1280px) {
  .container {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media screen and (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1280px !important;
  }
}

@media screen and (min-width: 1600px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1520px !important;
  }
}


.modal_text {
  font-size: 14px;
  color: #fff;
  padding-bottom: 4px;
}

.modal_text {
  font-size: 16px;
  font-family: 'RobotoCondensed' !important;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #fff;
  p {
    margin-top: -8px;
    text-align: left;
    margin-bottom: 28px;
    font-family: 'RobotoCondensed' !important;
  }
  b {
    font-family: 'RobotoCondensed' !important;
  }
}

.ant-message-custom-content {
  span {
    font-family: 'RobotoCondensed' !important;
  }
}

.modal_action {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 16px;
  width: 100%;
  z-index: 999999999;
  padding: 0px 0px 2px 16px;
  
  position: absolute;
  bottom: 0px;
  left: 0;
  background-color: #1b1b1b;
  padding: 12px;
}

.Toastify {
  position: relative;
  z-index: 9999999999999999;
  width: 100%;
}

.modal_action div {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.modal_action button {
  color: #fff;
  background: transparent !important;
  outline: none !important;
  border: none !important;
  text-transform: uppercase;
  font-weight: 500;
  margin-right: 16px;
  margin-left: 16px;
  letter-spacing: 2px;
}

.ant-select-selection-item {
  color: #000 !important;
}

.modal_action a {
  color: #fff;
  font-weight: 500;
  background: transparent !important;
  outline: none !important;
  border: none !important;
  text-transform: uppercase;
  text-decoration: none;
  margin-left: 12px;
}

@media screen and (max-width: 960px) {
  .modal_actions {
    div {
      flex-direction: column !important;
      button {
        margin-top: 10px;
        margin-right: 0px !important;
        &:first-child {
          margin-top: 0px !important;
        }
      }
    }
  }
}

.modal_actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    button {
      border-radius: 100px;
      background-color: rgba(255, 255, 255, 0.15);
      color: #fff;
      border: none;
      outline: none;
      font-size: 16px;
      padding: 6px 18px;
      margin-right: 12px;
      &:last-child {
        margin-right: 0px !important;
      }
      &.prim {
        background-color: #107be7 !important;
      }
      &.green {
        background-color: rgb(11, 177, 11) !important;
      }
      &.red {
        background-color: rgb(216, 7, 7) !important;
      }
      &.clear {
        background-color: transparent !important;
      }
    }
  }
}

.modal_action button:first-child {
  color: #fff;
  font-weight: 500;
  background: #FF0000 !important;
  outline: none !important;
  border: none !important;
  text-transform: uppercase;
  padding: 8px 24px 6px;
  border-radius: 10px;
  font-size: 20px;
  margin-left: 0px !important;
}

.modal_action button:disabled {
  color: rgb(164, 164, 164);
  cursor: default;
}

.ant-switch {
  background-color: rgba(255, 255, 255, 0.1);
  &:hover {
    background-color: rgba(255, 255, 255, 0.2) !important;
  }
  &.ant-switch-checked {
    background-color: #e60909 !important;
    &:hover {
      background-color: #c50000 !important;
    }
  }
}

.modal_form {
  div {
    width: 100%;
    margin-bottom: 16px;

    label {
      //font-family: 'Futura PT Book';
      font-size: 14px;
      margin-bottom: 6px;

      small {
        color: red;
      }
    }

    input,
    textarea,
    select {
      width: 100%;
      border: none;
      color: rgb(255, 255, 255);
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 10px;
      padding: 10px 12px;
      outline: none;
      font-size: 18px;

      &::placeholder {
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
}

.ant-upload-wrapper {
  .ant-upload-drag {
    border: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
    transition: all 0.3s ease;
    .ant-upload-text {
      color: #fff !important;
    }
    .ant-upload-hint {
      color: rgba(255, 255, 255, 0.8) !important;
    }
    .ant-upload-drag-icon {
      span {
        font-size: 42px;
      }
      img {
        max-height: 160px;
      }
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.15);
    }
  }
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
}

::-webkit-scrollbar-thumb {
  background-color: #babac07c;
  border-radius: 16px;
}

::-webkit-scrollbar-track:hover {}

::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
}

::-webkit-scrollbar {
  width: 6px;
  max-height: 6px;
}

.manager_table {
  border-radius: 10px;
  margin-bottom: 72px;
  overflow-x: scroll;
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: all 0.3s ease;
  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.5) !important;
    thead {
      border-bottom: 1px solid rgba(255, 255, 255, 0.4) !important;
    }
  }
}
.manager_table_x {
  width: 100%;
  thead {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    transition: all 0.3s ease;
    tr {
      th {
        padding: 8px 16px 4px;
        color: rgba(255, 255, 255, 0.6);
        user-select: none;
        cursor: pointer;
        letter-spacing: 1px;
        font-family: 'RobotoCondensed' !important;
        font-weight: 400;
      }
    }
  }
  tbody {
    tr {
      cursor: pointer;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      &:hover {
        td {
          color: #fff;
          &:nth-child(4) {
            a {
              &::after {
                width: 100% !important;
              }
            }
          }
        }
      }
      td {
        padding: 6px 16px 6px;
        color: rgba(255, 255, 255, 0.6);
        transition: all 0.3s ease;
        letter-spacing: 1px;
        font-family: 'RobotoCondensed' !important;
        font-weight: 400;
        a {
          color: rgba(255, 255, 255, 0.6);
          transition: all 0.3s ease;
          font-family: 'RobotoCondensed' !important;
          white-space: pre;
          
          max-width: 420px;
          overflow: hidden;
          display: inline-block;
          text-overflow: ellipsis;
          min-width: unset;
          &:hover {
            color: #fff;
          }
        }
        .btns {
          min-width: fit-content;
          display: flex;
        }
        button {
          background-color: transparent;
          width: 32px;
          height: 32px;
          border-radius: 100px;
          text-align: center;
          line-height: 1px;
          font-size: 18px;
          border: none;
          outline: none;
          color: rgba(255, 255, 255, 0.6);
          transition: all 0.3s ease;
          margin-right: 10px;
          &:last-child {
            margin-right: 0px;
          }
          &:hover {
            color: #fff;
            background-color: rgba(255, 255, 255, 0.2);
          }
        }
        span {
          position: relative;
          display: inline-block;
          img {
            width: 22px;
            max-height: 32px;
            border-radius: 4px;
          }
          &:before {
            transition: all 0.4s ease;
            content: " ";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            border-radius: 3px;
            background-color: rgba(255, 255, 255, 0.2);
            opacity: 0;
            cursor: pointer;
          }
          &:hover {
            &:before {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

a {
  text-decoration: none !important;
  position: relative !important;
  transition: all 0.3s ease;
  &:hover {
    color: #fff !important;
  }
}

.ant-checkbox-wrapper {
  span {
    &:nth-child(2) {
      color: rgba(255, 255, 255, 0.8);
    }
  }
}

.ant-dropdown {
  z-index: 999999;
}

.ant-picker  {
  color: #fff !important;
  background: #1a1a1a !important;
  border: 1px solid #212121 !important;
}

.ant-picker-dropdown {
  z-index: 9999999999 !important;
}

.ant-picker-clear {
  background-color: transparent !important;
  span {
    color: rgba(255, 255, 255, 0.3);
  }
}

.ant-picker-suffix {
  color: #ffffff47 !important;
}

.react-datepicker-popper {
  z-index: 999999999 !important;
  * {
    font-family: 'RobotoCondensed' !important;
  }
}

.ant-popover {
  z-index: 9999999999 !important;
  &.red {
    .ant-popover-arrow {
      &::before {
        background-color: #e10202 !important;
      }
    }
    .ant-popover-inner {
      background-color: #e10202 !important;
    }
  }
  &.green {
    .ant-popover-arrow {
      &::before {
        background-color: #00b400 !important;
      }
    }
    .ant-popover-inner {
      background-color: #00b400 !important;
    }
  }
  &.yellow {
    .ant-popover-arrow {
      &::before {
        background-color: #c69e00 !important;
      }
    }
    .ant-popover-inner {
      background-color: #c69e00 !important;
    }
  }
  .ant-popover-arrow {
    &::before {
      background-color: #343434 !important;
    }
  }
  .ant-popover-inner {
    background-color: #343434;
    box-shadow: 0 6px 12px rgba(0,0,0,0.6);
    padding: 4px 10px 6px;
    .ant-popover-title {
      color: #fff;
      margin-bottom: 0px;
      font-size: 16px;
    }
    .ant-popover-inner-content {
      color: rgba(255, 255, 255, 0.8);
      p {
        margin-bottom: 0px !important;
      }
    }
  }
}

.xchart_wrap {
  min-height: 380px;
  background-color: #ffffff03;
  border-radius: 6px;
  margin-bottom: 24px;
  .xchart_toolbar {
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;
    padding: 6px 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 6px;
    &:hover {
      border-color: rgba(255, 255, 255, 0.3);
    }
    .xchart_toolbar__item {
      width: fit-content;
      min-width: fit-content;
      border-right: 1px solid rgba(255, 255, 255, 0.1);
      display: flex;
      align-items: center;
      margin-right: 10px;
      &:last-child {
        border-right: none !important;
      }
      label {
        font-size: 14px;
        text-transform: uppercase;
        margin-right: 10px;
        opacity: 0.7;
        color: #fff;
        letter-spacing: 1px;
        line-height: 1;
        height: 11px;
      }
      input {
        background: transparent;
        border: none;
        outline: none;
        background-color: rgba(255, 255, 255, 0.05);
        text-align: center;
        width: 130px;
        margin-right: 12px;
        padding: 3px 0 0;
        border-radius: 6px;
        transition: all 0.3s ease;
        cursor: pointer;
        color: #fff;
        &:hover {
          background-color: rgba(255, 255, 255, 0.15);
        }
      }
    }
  }
}

input, select,
.ant-select-selection-placeholder,
.ant-select-item-option-content,
.ant-popover-title,
.ant-popover-inner-content,
.ant-popover-inner-content p {
  font-family: 'RobotoCondensed' !important;
}

.react-datepicker {
  background-color: #2b2b2b !important;
  color: #fff !important;
  border: 1px solid #444444 !important;
  .react-datepicker__header {
    background-color: #2b2b2b !important;
    border-bottom: 1px solid #404040 !important;
    .react-datepicker__current-month {
      color: #fff;
    }
  }
  .react-datepicker__day-name {
    color: #ffffff9c;
    transition: all 0.3s ease;
    &:hover {
      color: #000 !important;
    }
  }
  .react-datepicker__day {
    color: #ffffff9c;
    transition: all 0.3s ease;
    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
      color: #fff !important;
    }
    &.react-datepicker__day--selected {
      background-color: #2477BD;
      color: #fff !important;
      &:hover {
        background-color: #105fa0;
        color: #fff !important;
      }
    }
  }
  .react-datepicker__navigation {
    top: 8px !important;
  }
  .react-datepicker__triangle {
    &::after {
      border-bottom-color: #5b5b5b !important;
    }
  }
}

.ant-popover-inner-content {
  max-height: 230px !important;
  overflow-y: scroll !important;
}

.ant-popover-placement-btm {
  
}