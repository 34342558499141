* {
  //font-family: 'RobotoCondensed' !important;
}

.centered_wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 42px;
  width: 100%;
  .flexed_head {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 62px;
    width: 100%;
    .start {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .menu {
      width: 60%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-top: 14px;
      .menu_wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        a {
          display: block;
          width: fit-content;
          min-width: fit-content;
          margin: 0 20px;
          text-decoration: none;
          font-size: 20px;
          font-weight: 600;
          letter-spacing: .5px;
          line-height: 1;
          color: rgba(255, 255, 255, 0.7);
          cursor: pointer;
          transition: all 0.3s ease;
          //font-family: 'RobotoCondensed' !important;
          border-bottom: 1px solid transparent;
          &.active {
            color: #fff;
            //border-bottom: 1px solid #fff;
          }
          &:hover {
            color: #fff;
            //border-bottom: 1px solid #fff;
          }
        }
      }
    }
  }
  .fh_data {
    width: 100%;
    transition: all 0.3s ease;
  }
  .logo {
    margin-right: 20px;
    cursor: pointer;
    display: block;
    img {
      width: 180px;
      transition: all 0.3s ease;
      user-select: none;
    }
  }
  .title {
    font-size: 22px;
    font-weight: 800;
    letter-spacing: 0.4px;
    color: #fff;
    user-select: none;
    text-align: left;
    transition: all 0.3s ease;
    font-family: 'RobotoCondensed' !important;
  }
  .text {
    color: rgba(255, 255, 255, 0.6);
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    user-select: none;
    transition: all 0.3s ease;
    font-family: 'RobotoCondensed' !important;
  }
  .faqs {
    margin-top: 10px;
    width: 70%;
    .faqs_title {
      text-align: left;
      color: #fff;
      font-size: 24px;
      font-weight: 700;
      letter-spacing: 0.7px;
      margin-bottom: 18px;
      user-select: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-family: 'RobotoCondensed' !important;
      div {
        padding: 10px 20px;
        cursor: pointer;
        color: rgba(255, 255, 255, 0.7);
        transition: all 0.3s ease;
        border-bottom: 2px solid rgba(255, 255, 255, 0.2);
        &.active {
          color: #fff;
          border-bottom: 2px solid rgba(255, 255, 255, 1);
        }
      }
    }
    .faqs_items {
      p {
        color: #fff;
        a {
          color: #fff;
          font-weight: 700;
          border-bottom: 1px solid transparent;
          transition: all 0.3s ease;
          &:hover {
            border-bottom: 1px solid #fff;
          }
        }
      }
      .faqs_items__item {
        cursor: pointer;
      }
    }
  }
  .platforms {
    margin-top: 20px;
    width: 60%;
    .platforms_title {
      text-align: left;
      color: #fff;
      font-size: 24px;
      font-weight: 700;
      letter-spacing: 0.7px;
      margin-bottom: 18px;
      user-select: none;
      //font-family: 'RobotoCondensed' !important;
    }
    .platforms_items {
      display: flex;
      flex-direction: row;
      align-items: center;
      .platforms_item {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 12px;
        border: 2px solid #fff;
        opacity: 0.7;
        transition: all 0.4s ease;
        margin-right: 26px;
        padding: 20px;
        height: 268px;
        &:last-child {
          margin-right: 0px;
        }
        &:hover {
          opacity: 1;
        }
        div {
          &:nth-child(1) {
            color: #fff;
            font-size: 42px;
            user-select: none;
          }
          &:nth-child(2) {
            color: #fff;
            font-size: 22px;
            font-weight: 700;
            letter-spacing: 0.4px;
            margin-bottom: 12px;
            user-select: none;
            font-family: 'RobotoCondensed' !important;
          }
          &:nth-child(3) {
            margin-bottom: 12px;
            button,
            a {
              display: block;
              background-color: #fff;
              color: #000;
              font-size: 16px;
              font-weight: 600;
              letter-spacing: 0.5px;
              line-height: 1;
              border: none;
              outline: none;
              padding: 13px 18px 10px;
              border-radius: 10px;
              transition: all 0.3s ease;
              text-decoration: none !important;
              //font-family: 'RobotoCondensed' !important;
              &:hover {
                color: #000 !important;
                background-color: rgb(226, 226, 226);
              }
              &:active {
                color: #000 !important;
                background-color: rgb(193, 193, 193);
              }
            }
          }
          &:nth-child(4) {
            font-size: 12px;
            color: rgba(255, 255, 255, 0.6);
            font-weight: 400;
            text-align: center;
            margin-bottom: 6px;
            user-select: none;
            font-family: 'RobotoCondensed' !important;
          }
          &:nth-child(5) {
            font-size: 12px;
            color: rgba(255, 255, 255, 0.6);
            font-weight: 400;
            text-align: center;
            margin-bottom: 6px;
            user-select: none;
            font-family: 'RobotoCondensed' !important;
          }
          &:nth-child(6) {
            font-size: 12px;
            color: rgba(255, 255, 255, 0.6);
            font-weight: 400;
            text-align: center;
            user-select: none;
            font-family: 'RobotoCondensed' !important;
            a {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 568px) {
  .platforms_item {
    height: 308px;
  }
}

@media screen and (max-width: 768px) {
  .flexed_head {
    //flex-direction: column !important;
    .title {
      text-align: left !important;
      margin-top: 20px;
    }
    .text {
      text-align: left !important;
      margin-top: -10px;
    }
  }
  .platforms {
    width: 100% !important;
    .platforms_items {
      .platforms_item {
        width: 100% !important;
        margin-right: 10px !important;
        &:last-child {
          margin-right: 0px !important;
        }
      }
    }
  }
  .faqs {
    width: 100% !important;
  }
}

.global_release {
  margin-top: 20px;
  font-size: 14px;
  color: #fff !important;
  text-decoration: none;
  border-bottom: 1px dashed;
  width: fit-content;
  opacity: 0.7;
  transition: all 0.3s ease;
  font-family: 'RobotoCondensed' !important;
  &:hover {
    opacity: 1 !important;
    color: #fff !important;
  }
}

.redded {
  color: red;
}

.docs_wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 22px;
  .docs_menu {
    width: 30%;
    padding-right: 40px;
    div {
      color: rgba(255, 255, 255, 0.7);
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 8px;
      font-size: 16px;
      padding: 8px 16px;
      font-weight: 500;
      margin-bottom: 12px;
      transition: all 0.3s ease;
      font-family: 'RobotoCondensed' !important;
      cursor: pointer;
      &.active {
        color: rgba(255, 255, 255, 1);
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
  }
  .docs_page {
    width: 70%;
    h4 {
      color: #fff;
      font-weight: 700;
      font-size: 28px;
    }
    .ffx {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      div {
        &:nth-child(1) {
          width: 65%;
          color: #fff;
          letter-spacing: 1.3px;
          font-family: 'RobotoCondensed' !important;
        }
        &:nth-child(2) {
          width: 35%;
          img {
            width: 100%;
          }
        }
      }
    }
    ol {
      li {
        color: #fff;
        font-family: 'RobotoCondensed' !important;
      }
    }
    p {
      color: #fff;
      letter-spacing: 1.3px;
      font-family: 'RobotoCondensed' !important;
      b {

      }
      span {

      }
      a {
        color: #fff;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.chw {
  width: 100%;
  padding: 140px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 72px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    width: 100%;
    font-family: 'RobotoCondensed' !important;
  }
  p {
    color: #fff;
    text-align: center;
    width: 100%;
    letter-spacing: 1.3px;
    font-size: 26px;
    font-family: 'RobotoCondensed' !important;
  }
  .chw_flx {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 32px;
    a {
      background-color: #fff;
      color: #000;
      font-weight: 600;
      font-size: 16px;
      padding: 10px 22px;
      border-radius: 10px;
      border: none;
      outline: none;
      text-decoration: none;
      font-family: 'RobotoCondensed' !important;
      &:hover {
        color: #000 !important;
        &:nth-child(2) {
          color: #fff !important;
        }
      }
      &:nth-child(2) {
        background-color: transparent;
        color: #fff;
        margin-left: 20px;
      }
    }
  }
}

.mob_menu {
  display: none;
  flex-direction: column;
  align-items: flex-end;
  .mm_wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    a {
      color: rgba(255, 255, 255, 0.7);
      font-size: 24px;
      margin: 0 8px;
      &.active {
        color: #fff !important;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .chw {
    padding-top: 100px !important;
  }
  .faqs_title {
    div {
      font-size: 16px;
      white-space: pre;
      padding: 8px 16px !important;
    }
  }
  .flexed_head {
    padding-top: 24px !important;
    .logo {
      img {
        width: 140px !important;
      }
    }
    .fh_data {
      display: none !important;
    }
    .menu {
      display: none !important;
    }
    .mob_menu {
      display: flex !important;
    }
  }
  .docs_wrap {
    flex-direction: column !important;
    .docs_menu {
      width: 100% !important;
      padding-right: 0px !important;
    }
    .docs_page {
      width: 100% !important;
      padding-top: 20px;
      .ffx {
        flex-direction: column !important;
        div {
          width: 100% !important;
          &:nth-child(1) {
            margin-bottom: 18px;
          }
        }
      }
    }
  }
}